<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-col
    cols="12"
    md="4"
  >
    <div class="d-none d-md-block">
      <v-card
        width="100%"
        class="mx-2 pa-4"
      >
        <div class="d-flex justify-space-between align-center">
          <h1
            primary-title
            class="pl-3 mb-4"
          >
            PVP
          </h1>
        </div>
        <div class="d-flex justify-space-between align-center">
          <h4 class="mb-4">
            {{ $t('lbl.tarif') }}
          </h4>
          <h4 class="mb-4">
            <h4>
              {{ data_markups.priceTarifa | currency }}
            </h4>
          </h4>
        </div>
        <div class="d-flex justify-space-between align-center">
          <h4>
            {{ $t('lbl.cargoSuplement') }}
          </h4>
          <h4>
            <h4>
              {{ (data_markups.precioSuplementPayOnline + data_markups.precioSuplementPayRentator) | currency }}
            </h4>
          </h4>
        </div>
        <!---->
        <div class="d-flex justify-space-between align-center ml-2">
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ $t('lbl.seguro') }}
          </span>
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ data_markups.tarifaSeguro | currency }}
          </span>
        </div>
        <div
          v-for="(suplemento, indSupl) in data_markups.suplementos"
          :key="indSupl"
          class="d-flex justify-space-between align-center ml-2"
        >
          <fragment
            v-if="
              suplemento.suplemento.slug.toLowerCase() !== 'tarifa-de-retorno' &&
                suplemento.check === true &&
                suplemento.priceAll > 0
            "
          >
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            >
              {{ suplemento.suplemento.name }}
            </span>
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            >
              {{ suplemento.priceAll | currency }}
            </span>
          </fragment>
        </div>
        <div class="d-flex justify-space-between align-center ml-2">
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ $t('lbl.depositoGarantia') }}
          </span>
          <span
            class="pb-0 mb-0 pt-0 mt-0"
            style="font-size: 12px"
          >
            {{ data_markups.tarifaGarantia | currency }}
          </span>
        </div>
        <fragment v-if="data_markups.precioTarifaRetorno > 0">
          <div class="d-flex justify-space-between align-center ml-2">
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            >
              {{ $t('lbl.tarifaRetorno') }}
            </span>
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            >
              {{ data_markups.precioTarifaRetorno | currency }}
            </span>
          </div>
        </fragment>
      </v-card>
      <v-card
        width="100%"
        class="mx-2 mt-3 pa-4 pt-0"
      >
        <div class="d-flex justify-space-between align-center">
          <h3 class="my-2">
            {{ $t('lbl.totalPay') }}
          </h3>
          <h3>
            <h4>
              {{ data_markups.priceTotal | currency }}
            </h4>
          </h3>
        </div>
        <fragment v-if="data_markups.precioSuplementPayOnline > 0">
          <div class="d-flex justify-space-between align-center">
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            > {{ $t('lbl.payOnline') }} </span>
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            >
              {{ data_markups.precioSuplementPayOnline | currency }}
            </span>
          </div>
        </fragment>

        <fragment v-if="data_markups.precioSuplementPayRentator > 0">
          <div class="d-flex justify-space-between align-center">
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            >
              {{ $t('lbl.payRent') }}
            </span>
            <span
              class="pb-0 mb-0 pt-0 mt-0"
              style="font-size: 12px"
            >
              {{ data_markups.precioSuplementPayRentator | currency }}
            </span>
          </div>
        </fragment>
      </v-card>
    </div>
  </v-col>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    data_markups: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
  },
}
</script>
