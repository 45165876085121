var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"my-2",attrs:{"width":"100%"}},[_c('v-container',{staticClass:"pa-6",attrs:{"grid-list-xs":""}},[_c('v-row',{staticStyle:{"border":"1px solid #e5e5e5","border-radius":"5px"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-block"},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('p',{staticClass:"mb-3 d-flex justify-start"},[_c('b',[_vm._v(" "+_vm._s(_vm.product.name)+" ")])])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('fragment',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiCarDoor)+" ")])]}}])},[_c('span',[_c('fragment',[_vm._v(" "+_vm._s(_vm.$t('lbl.typeCar'))+" "+_vm._s(_vm.product.tipo_auto_id ? _vm.product.tipo_auto.name : '')+" "+_vm._s(_vm.product.cant_puertas)+" "+_vm._s(_vm.$t('lbl.doors').toLowerCase())),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_paxs)+" "+_vm._s(_vm.$t('lbl.travels').toLowerCase())+" "),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_maletas_small ? ((_vm.product.cant_maletas_small) + " " + (_vm.$t('lbl.cantMaletas').toLowerCase()) + " " + (_vm.$t( 'lbl.small' ).toLowerCase())) : '')+" "),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_maletas_big ? ((_vm.product.cant_maletas_big) + " " + (_vm.$t('lbl.cantMaletas').toLowerCase()) + " " + (_vm.$t( 'lbl.big' ).toLowerCase())) : '')+" "),_c('br')])],1)]),_c('b',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(_vm.product.cant_puertas)+" ")])],1),_c('br'),_c('fragment',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiAccountOutline)+" ")])]}}])},[_c('span',[_c('fragment',[_vm._v(" "+_vm._s(_vm.$t('lbl.typeCar'))+" "+_vm._s(_vm.product.tipo_auto_id ? _vm.product.tipo_auto.name : '')+" "+_vm._s(_vm.product.cant_puertas)+" "+_vm._s(_vm.$t('lbl.doors').toLowerCase())),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_paxs)+" "+_vm._s(_vm.$t('lbl.travels').toLowerCase())+" "),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_maletas_small ? ((_vm.product.cant_maletas_small) + " " + (_vm.$t('lbl.cantMaletas').toLowerCase()) + " " + (_vm.$t( 'lbl.small' ).toLowerCase())) : '')+" "),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_maletas_big ? ((_vm.product.cant_maletas_big) + " " + (_vm.$t('lbl.cantMaletas').toLowerCase()) + " " + (_vm.$t( 'lbl.big' ).toLowerCase())) : '')+" "),_c('br')])],1)]),_c('b',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.product.cant_paxs))])],1),_c('br'),_c('fragment',[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiBagSuitcaseOutline)+" ")])]}}])},[_c('span',[_c('fragment',[_vm._v(" "+_vm._s(_vm.$t('lbl.typeCar'))+" "+_vm._s(_vm.product.tipo_auto_id ? _vm.product.tipo_auto.name : '')+" "+_vm._s(_vm.product.cant_puertas)+" "+_vm._s(_vm.$t('lbl.doors').toLowerCase())),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_paxs)+" "+_vm._s(_vm.$t('lbl.travels').toLowerCase())+" "),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_maletas_small ? ((_vm.product.cant_maletas_small) + " " + (_vm.$t('lbl.cantMaletas').toLowerCase()) + " " + (_vm.$t( 'lbl.small' ).toLowerCase())) : '')+" "),_c('br'),_vm._v(" "+_vm._s(_vm.product.cant_maletas_big ? ((_vm.product.cant_maletas_big) + " " + (_vm.$t('lbl.cantMaletas').toLowerCase()) + " " + (_vm.$t( 'lbl.big' ).toLowerCase())) : '')+" "),_c('br')])],1)]),_c('b',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(("" + (parseInt(_vm.product.cant_maletas_big) + parseInt(_vm.product.cant_maletas_small))))+" ")])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.$t('lbl.capacityComb'))+": "+_vm._s(_vm.product.capacidad_combustible)+" "+_vm._s(_vm.product.um_combustible)+" ")]),_c('br'),_c('span',{staticClass:"caption mt-0"},[_vm._v(" "+_vm._s(_vm.$t('lbl.consumoComb'))+": "+_vm._s(_vm.product.consumo_combustible)+" "+_vm._s(("km/" + (_vm.product.um_combustible)))+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[(_vm.rentadora.icon !== null)?_c('v-img',{attrs:{"src":_vm.rutaPublic + _vm.rentadora.icon,"size":"20","height":"30","contain":"","aspect-ratio":"1"}}):_vm._e()],1)],1)],1)]),_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":_vm.nameB2B === 'ttttt'
              ? _vm.rutaS3 + _vm.remplaceUrl(_vm.image)
              : _vm.nameB2B === 'wwwww'
                ? _vm.rutaS3 + _vm.image
                : _vm.rutaPublic + _vm.image,"size":"100","height":"105"}})],1),_c('v-col',{staticClass:"py-0 mb-5",attrs:{"cols":"12","offset-md":"2","md":"10"}},_vm._l((_vm.product.caracteristicas),function(cara,indCara){return _c('fragment',{key:indCara,attrs:{"top":""}},[(cara)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(cara.icon)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(cara.name))])]):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }