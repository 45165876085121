<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-card
    class="my-2"
    width="100%"
  >
    <v-container
      grid-list-xs
      class="pa-6"
    >
      <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
        <v-col cols="12">
          <div class="d-block">
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <p class="mb-0 d-flex justify-start">
                  <b>{{ $t('lbl.detallesReserva') }}</b>
                </p>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <b>{{ $t('lbl.recogida') }}</b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-icon
                      v-if="recogida.pto_recogida"
                      color="primary"
                      class="mr-2"
                      small
                    >
                      {{ recogida.punto_recogida.icon }}
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    md="11"
                  >
                    <span class="caption">
                      {{ recogida.name }}<br />
                      {{ recogida.direccion }}<br />
                      {{ `${recogida.city}, ${recogida.state}, ${recogida.country}` }}<br />
                    </span>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-icon
                      color="primary"
                      class="mr-2"
                      small
                    >
                      mdi-calendar-range
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    md="11"
                  >
                    <span class="caption">
                      {{ $moment(dateRecogida).format('lll') }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <b>{{ $t('lbl.entrega') }}</b>
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-icon
                      v-if="entrega.pto_recogida"
                      color="primary"
                      class="mr-2"
                      small
                    >
                      {{ entrega.punto_recogida.icon }}
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    md="11"
                  >
                    <span class="caption">
                      {{ entrega.name }}<br />
                      {{ entrega.direccion }}<br />
                      {{ `${entrega.city}, ${entrega.state}, ${entrega.country}` }}<br />
                    </span>
                  </v-col>

                  <v-col
                    cols="12"
                    md="1"
                  >
                    <v-icon
                      color="primary"
                      class="mr-2"
                      small
                    >
                      mdi-calendar-range
                    </v-icon>
                  </v-col>
                  <v-col
                    cols="12"
                    md="11"
                  >
                    <span class="caption">
                      {{ $moment(dateEntrega).format('lll') }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <b>{{ $t('lbl.daysRent') }}</b>: {{ diasReservar }}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    recogida: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    dateRecogida: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    entrega: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    dateEntrega: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    diasReservar: {
      type: Number,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
  },
}
</script>
