<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <v-card
        class="my-2"
        width="100%"
      >
        <v-container
          grid-list-xs
          class="pa-6"
        >
          <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
            <v-col cols="12">
              <div class="d-block">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.conductorPrincipal') }}</b>
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.name') }}</b>:
                      {{
                        `${clients.nameConductorPrincipal} ${
                          clients.nameSecoundConductorPrincipal ? clients.nameSecoundConductorPrincipal : ''
                        } ${clients.apellidosConductorPrincipal}`
                      }}
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.nationality') }}</b>:
                      {{ `${clients.nationalityConductorPrincipal.name}` }}
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{
                        `${
                          clients.nationalityConductorPrincipal.code === 'cu'
                            ? $t('lbl.passport')
                            : $t('lbl.noDocument')
                        }`
                      }}</b>:
                      {{ `${clients.noDocumentConductorPrincipal}` }}
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.birthday') }}</b>:
                      {{ $moment(clients.birthdayConductorPrincipal).format('D/M/Y') }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
    <v-col
      v-if="clients.nameConductorAditional"
      cols="12"
      md="6"
    >
      <v-card
        class="my-2"
        width="100%"
      >
        <v-container
          grid-list-xs
          class="pa-6"
        >
          <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
            <v-col cols="12">
              <div class="d-block">
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.conductorAditional') }}</b>
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.name') }}</b>:
                      {{
                        `${clients.nameConductorAditional} ${
                          clients.nameSecoundConductorAditional ? clients.nameSecoundConductorAditional : ''
                        } ${clients.apellidosConductorAditional}`
                      }}
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.nationality') }}</b>:
                      {{ `${clients.nationalityConductorAditional.name}` }}
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{
                        `${
                          clients.nationalityConductorAditional.code === 'cu'
                            ? $t('lbl.passport')
                            : $t('lbl.noDocument')
                        }`
                      }}</b>:
                      {{ `${clients.noDocumentConductorAditional}` }}
                    </p>
                    <p class="mb-0 d-flex justify-start">
                      <b>{{ $t('lbl.birthday') }}</b>:
                      {{ $moment(clients.birthdayConductorAditional).format('D/M/Y') }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    clients: {
      type: Object,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
  },
}
</script>
