<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-card
    class="my-2"
    width="100%"
  >
    <v-container
      grid-list-xs
      class="pa-6"
    >
      <v-row style="border: 1px solid #e5e5e5; border-radius: 5px;">
        <v-col cols="8">
          <div class="d-block">
            <v-row no-gutters>
              <v-col
                cols="12"
                md="12"
              >
                <p class="mb-3 d-flex justify-start">
                  <b>
                    {{ product.name }}
                  </b>
                </p>
              </v-col>
              <!--ICONS PUERTAS, MALESTAS, PAXS-->
              <v-col
                cols="12"
                md="3"
              >
                <fragment>
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiCarDoor }}
                      </v-icon>
                    </template>
                    <span>
                      <fragment>
                        {{ $t('lbl.typeCar') }}
                        {{ product.tipo_auto_id ? product.tipo_auto.name : '' }}
                        {{ product.cant_puertas }} {{ $t('lbl.doors').toLowerCase() }}<br />
                        {{ product.cant_paxs }}
                        {{ $t('lbl.travels').toLowerCase() }} <br />

                        {{
                          product.cant_maletas_small
                            ? `${product.cant_maletas_small} ${$t('lbl.cantMaletas').toLowerCase()} ${$t(
                              'lbl.small',
                            ).toLowerCase()}`
                            : ''
                        }}
                        <br />
                        {{
                          product.cant_maletas_big
                            ? `${product.cant_maletas_big} ${$t('lbl.cantMaletas').toLowerCase()} ${$t(
                              'lbl.big',
                            ).toLowerCase()}`
                            : ''
                        }}
                        <br />
                      </fragment>
                    </span>
                  </v-tooltip>
                  <b class="ml-2">
                    {{ product.cant_puertas }}
                  </b>
                </fragment><br />
                <fragment>
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiAccountOutline }}
                      </v-icon>
                    </template>
                    <span>
                      <fragment>
                        {{ $t('lbl.typeCar') }}
                        {{ product.tipo_auto_id ? product.tipo_auto.name : '' }}
                        {{ product.cant_puertas }} {{ $t('lbl.doors').toLowerCase() }}<br />
                        {{ product.cant_paxs }}
                        {{ $t('lbl.travels').toLowerCase() }} <br />

                        {{
                          product.cant_maletas_small
                            ? `${product.cant_maletas_small} ${$t('lbl.cantMaletas').toLowerCase()} ${$t(
                              'lbl.small',
                            ).toLowerCase()}`
                            : ''
                        }}
                        <br />
                        {{
                          product.cant_maletas_big
                            ? `${product.cant_maletas_big} ${$t('lbl.cantMaletas').toLowerCase()} ${$t(
                              'lbl.big',
                            ).toLowerCase()}`
                            : ''
                        }}
                        <br />
                      </fragment>
                    </span>
                  </v-tooltip>
                  <b class="ml-3">{{ product.cant_paxs }}</b>
                </fragment><br />
                <fragment>
                  <v-tooltip
                    top
                    color="primary"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiBagSuitcaseOutline }}
                      </v-icon>
                    </template>
                    <span>
                      <fragment>
                        {{ $t('lbl.typeCar') }}
                        {{ product.tipo_auto_id ? product.tipo_auto.name : '' }}
                        {{ product.cant_puertas }} {{ $t('lbl.doors').toLowerCase() }}<br />
                        {{ product.cant_paxs }}
                        {{ $t('lbl.travels').toLowerCase() }} <br />

                        {{
                          product.cant_maletas_small
                            ? `${product.cant_maletas_small} ${$t('lbl.cantMaletas').toLowerCase()} ${$t(
                              'lbl.small',
                            ).toLowerCase()}`
                            : ''
                        }}
                        <br />
                        {{
                          product.cant_maletas_big
                            ? `${product.cant_maletas_big} ${$t('lbl.cantMaletas').toLowerCase()} ${$t(
                              'lbl.big',
                            ).toLowerCase()}`
                            : ''
                        }}
                        <br />
                      </fragment>
                    </span>
                  </v-tooltip>
                  <b class="ml-2">
                    {{ `${parseInt(product.cant_maletas_big) + parseInt(product.cant_maletas_small)}` }}
                  </b>
                </fragment>
              </v-col>
              <!--CAPACIDADES-->
              <v-col
                cols="12"
                md="6"
              >
                <span class="caption">
                  {{ $t('lbl.capacityComb') }}: {{ product.capacidad_combustible }}
                  {{ product.um_combustible }}
                </span>
                <br />
                <span class="caption mt-0">
                  {{ $t('lbl.consumoComb') }}: {{ product.consumo_combustible }}
                  {{ `km/${product.um_combustible}` }}
                </span>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-img
                  v-if="rentadora.icon !== null"
                  :src="rutaPublic + rentadora.icon"
                  size="20"
                  height="30"
                  contain
                  aspect-ratio="1"
                ></v-img>
              </v-col>
            </v-row>
          </div>
        </v-col>
        <v-col cols="4">
          <v-img
            :src="
              nameB2B === 'ttttt'
                ? rutaS3 + remplaceUrl(image)
                : nameB2B === 'wwwww'
                  ? rutaS3 + image
                  : rutaPublic + image
            "
            size="100"
            height="105"
          ></v-img>
        </v-col>
        <!--CARACTERISTICAS-->
        <v-col
          cols="12"
          offset-md="2"
          md="10"
          class="py-0 mb-5"
        >
          <fragment
            v-for="(cara, indCara) in product.caracteristicas"
            :key="indCara"
            top
          >
            <v-tooltip
              v-if="cara"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="primary"
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ cara.icon }}
                </v-icon>
              </template>
              <span>{{ cara.name }}</span>
            </v-tooltip>
          </fragment>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
  mdiCar,
  mdiBed,
  mdiCarDoor,
  mdiAccountOutline,
  mdiBagSuitcaseOutline,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    product: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    rentadora: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    image: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      rutaS3: process.env.VUE_APP_API_URL_S3_CAR,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
        mdiCar,
        mdiBed,
        mdiCarDoor,
        mdiAccountOutline,
        mdiBagSuitcaseOutline,
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {},
      diasReservar: 0,
      data_markups: {},
      precioSuplementPayOnline: 0,
      precioSuplementPayRentator: 0,
      precioTarifaRetorno: 0,

      loading: false,

      categories: [],
      proveedores: [],
      itemsProveedor: [],
      searchProveedor: null,
    }
  },
  computed: {
    ...mapState({
      oficinaRenta: state => state.app.oficinaRenta,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
  },
}
</script>
